import React from "react"
import tw from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

// Components
import Container from "@components/container"
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHero from "@components/staticHero"
import KeyPoints from "@components/keyPoints"
import ScrollChart from "@components/narcolepsy/scrollChart"
import { heading1, Heading2 } from "@styles/ui"
import PatientVoice from "@components/PatientVoice"
import PrimaryCta from "@components/PrimaryCta"

// Assets
import nineIcon from "@images/living-with-narcolepsy/nine-icons.svg"
import groupIconsSm from "@images/living-with-narcolepsy/icon-group-sm.svg"
import groupIconsLg from "@images/living-with-narcolepsy/icon-group-lg.svg"
import dumbbell from "@images/icons/round/icon-dumbbell.svg"
import lemon from "@images/icons/round/icon-lemon.svg"
import clipboard from "@images/icons/round/icon-clipboard.svg"

const boxStyles = tw`font-cocon text-studio text-[26px] leading-[30px] bg-shadowboxBg relative overflow-hidden col-span-full pt-6 pl-6 mb-8 mx-auto max-w-[340px] rounded-[30px] md:(col-span-2 my-0 text-[30px] leading-[35px]) xl:(col-start-1 col-span-5 max-w-full ml-0 pt-10 pl-8 mr-3)`

const keyPointsStyle = tw`text-sm text-left leading-[140%] xl:(text-xl)`

const keyPoints = [
  {
    image: dumbbell,
    text: (
      <p css={keyPointsStyle}>Discuss your exercise habits and lifestyle.</p>
    ),
  },
  {
    image: lemon,
    text: (
      <p css={keyPointsStyle}>
        Ask about your diet, including sodium consumption.
      </p>
    ),
  },
  {
    image: clipboard,
    text: (
      <p css={keyPointsStyle}>
        Talk about other conditions you may have or may be at risk for.
      </p>
    ),
  },
]

const LivingWithNarcolepsy = ({ location, data }) => {
  return (
    <Layout location={location}>
      <Seo
        title="Risk Factors & Cardiovascular Comorbidities with Narcolepsy"
        description="Consider your heart health when managing your narcolepsy symptoms. Learn about risk factors and cardiovascular comorbidities associated with narcolepsy. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero eyebrow="Living with Narcolepsy" gradient="purple-red">
        <span css={[heading1, tw`font-cocon leading-[1] md:mr-[100px]`]}>
          Consider your heart health when managing <br tw="hidden lg:block" />
          your narcolepsy
        </span>
      </StaticHero>

      <section tw="mt-[30px] md:mt-12">
        <Container>
          <h2 css={[Heading2, tw`pb-6 text-center md:pb-0`]}>
            People with narcolepsy are at a greater risk of cardiovascular
            comorbidities compared to people without narcolepsy
          </h2>
          <ScrollChart>
            <div tw="pb-4 text-center">
              <GatsbyImage
                tw="min-w-[512px] mx-auto lg:w-[960px]"
                image={getImage(data.chartImage)}
                alt="Graphic depicting comorbidities for people with narcolepsy, including 2.5x increased risk† of stroke*, 1.6x increased risk† of heart attack*, ~1.5x increased risk† of high cholesterol†, 2.6x increased risk† of heart failure*, and ~1.3x increased risk† of high blood pressure†"
              />
            </div>
          </ScrollChart>
          <ul tw="max-w-[740px] text-[12px] leading-3 mx-auto md:(text-xs leading-[19px])">
            <li tw="mb-3 px-4 -indent-1.5 md:px-0">
              <sup>*</sup>Based on an analysis of 9312 people with narcolepsy
              and 46,559 people without narcolepsy matched by age, sex,
              geographic region, and payer who had continuous insurance coverage
              between the years of 2006 and 2010.
            </li>
            <li tw="mb-3 px-4 -indent-1.5 md:px-0">
              <sup>&dagger;</sup>Based on an interview study of 320 people with
              narcolepsy compared to 1464 people without narcolepsy matched for
              age, sex, and body mass index.
            </li>
            <li tw="mb-7 px-4 -indent-1.5 md:(px-0 mb-12)">
              <sup>&Dagger;</sup>Odds ratio
            </li>
          </ul>
          <div
            tw="mx-auto before:(block w-[124px] mb-3.5 h-1.5 rounded-md gradientRed mx-auto) xl:(before:(mb-6)) text-center w-full md:max-w-[550px] lg:max-w-[850px]"
            id="too-much-sodium"
          >
            <h2 css={Heading2}>
              Too much sodium intake may increase the chances of cardiovascular
              disease
            </h2>
            <p tw="text-sm mt-6 xl:text-xl">
              While sodium typically comes from what you eat and drink, it can
              also come from certain medications
            </p>
          </div>
        </Container>
      </section>

      <section tw="narc-purple-red-gradient mt-[30px] md:mt-12">
        <Container
          addedStyles={tw`flex flex-col  text-white md:(flex-row justify-between py-12 gap-6)`}
        >
          <div
            css={[
              boxStyles,
              tw`before:(block relative -top-2 w-[56px] mb-3.5 h-1 rounded-md narc-purple-red-gradient-lighter) md:(before:(w-[77px] h-1.5)) xl:before:(mb-4)`,
              tw`w-full max-w-[335px] pb-2.5 mb-6 self-center mt-8 md:(mb-0 mt-0 w-1/2 max-w-[470px] pt-10)`,
            ]}
          >
            <p tw="-mt-3 pb-2 text-[26px] md:pr-[60px] lg:(pr-[120px] text-[30px])">
              About 9 out of 10 Americans consume <br tw="md:hidden" /> too much
              sodium*
            </p>
            <picture tw="flex">
              <img
                src={nineIcon}
                alt=""
                tw="w-[290px] relative top-1 lg:mx-[unset] md:(w-[404px] top-3)"
              />
            </picture>
          </div>
          <div tw="md:w-1/2 lg:max-w-[560px]">
            <h2 css={[Heading2, tw`text-white pb-6`]}>
              Sodium intake is a modifiable risk factor
            </h2>
            <p tw="pb-6 text-sm font-extrabold md:text-xl">
              For most adults, reducing excess sodium intake by 1000 mg per day
              can help reduce the risk for high blood pressure, heart diseases,
              and stroke.
            </p>
            <p tw="pb-6 font-extrabold text-[12px] leading-4 -indent-1 pl-1 md:(pb-0 text-xs leading-[19px])">
              <sup>*</sup>According to a survey by the Centers for Disease
              Control and Prevention, 89% of adults in the US aged 19 years and
              older consume more than the limit of 2300 mg of dietary sodium per
              day, as recommended by the US Department of Health and Human
              Services and US Department of Agriculture in the 2015-2020 Dietary
              Guidelines for Americans.
            </p>
          </div>
        </Container>
      </section>

      <section tw="bg-whisper py-[30px] md:py-12">
        <Container addedStyles={tw`text-center`}>
          <h2 css={[heading1, tw`text-merlin font-bold`]}>
            Other factors may also increase the risk of cardiovascular
            conditions
          </h2>
          <p tw="text-xl pb-6 md:pb-10">
            For example, some risk factors for cardiovascular disease are
            &quot;modifiable.&quot;
            <br tw="hidden md:block" /> That means you may be able to address
            them through lifestyle changes. These changes include:
          </p>
          <picture>
            <source media="(min-width: 768px)" srcSet={groupIconsLg} />
            <source media="(min-width: 0px)" srcSet={groupIconsSm} />
            <img
              src={groupIconsLg}
              alt="Smoking, physical activity, diet, body weight, sodium intake"
              tw="w-full"
            />
          </picture>
        </Container>
      </section>

      <PatientVoice
        quote={
          <>
            Monitoring sodium is important to me because too much sodium can
            increase the risk of cardiovascular disease. And I know that in
            general, I have a higher risk for cardiovascular disease since I
            have narcolepsy. I also have a family history of
            hypertension.&rdquo;
          </>
        }
        patient={"Grace"}
        diagnosis={"Diagnosed with narcolepsy type 1."}
        imageData={data}
        cta={{
          text: "what is xywav",
          url: "/narcolepsy/what-is-xywav/",
          mdSmall: true,
        }}
        footnote="Compensated for her time. Results may vary."
      />

      <section tw="narc-purple-red-gradient py-[30px] md:(py-12 mt-0)">
        <Container grid addedStyles={tw`text-center`}>
          <h2
            css={[
              Heading2,
              tw`text-white text-center font-bold  mx-auto mb-[25px] col-span-full xl:([grid-column: 2/-2])`,
            ]}
          >
            Talk to your doctor about all aspects of your health
          </h2>
          <p tw="text-xl leading-[24px]  text-white mb-[25px] font-extrabold col-span-full">
            When creating a narcolepsy management plan with your doctor, be sure
            to discuss all aspects of your overall health. <br tw="md:hidden" />
            Tips include:
          </p>
        </Container>
        <Container>
          <KeyPoints points={keyPoints} />
          <div tw="text-center pt-8">
            <PrimaryCta
              url="/pdf/doctor-discussion-guide-narcolepsy.pdf"
              isExternal
              noInterstitial
              isWhite
            >
              Get the Discussion Guide
            </PrimaryCta>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default LivingWithNarcolepsy

export const query = graphql`
  query {
    patientVoice: file(
      relativePath: { eq: "living-with-narcolepsy/grace.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 705, placeholder: NONE)
      }
    }
    patientVoiceMobile: file(
      relativePath: { eq: "living-with-narcolepsy/graceMobile.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 705, placeholder: NONE)
      }
    }
    chartImage: file(relativePath: { eq: "living-with-narcolepsy/chart.png" }) {
      childImageSharp {
        gatsbyImageData(width: 960, placeholder: NONE)
      }
    }
  }
`
